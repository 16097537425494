import React, { useEffect, useContext, useMemo, useState } from 'react'
import Sticky from 'react-stickynode'
import moment from 'moment'
import TransparentButton from '../../components/common/Buttons/transparentButton'
import SearchInput from '../../components/common/Input/SearchInput'
import { formatAmount } from '../../utils/formatNumber'
import { veTHEsContext } from '../../context/veTHEsConetext'
import CreateModal from './createModal'
import ManageModal from './manageModal'
import useWalletModal from '../../hooks/useWalletModal'
import { useWithdraw } from '../../hooks/useLock'
import { useTHEAsset } from '../../hooks/useGeneral'
import NoFound from '../../components/common/NoFound'
import usePrices from '../../hooks/usePrices'
import StyledButton from '../../components/common/Buttons/styledButton'
import { useWeb3Wagmi } from '../../hooks/useWeb3'
import { managers } from '../../config/constants/managers'
import { upperFirst } from 'lodash'
import { truncateEthAddress } from '../../utils'
import Spinner from '../../components/common/Spinner'

const Lock = ({ setOption }) => {
  const [searchText, setSearchText] = useState('')
  const [selectedItem, setSelectedItem] = useState(null)
  const [isCreateOpen, setIsCreateOpen] = useState(false)
  const [isManageOpen, setIsManageOpen] = useState(false)
  const { veTHEs } = useContext(veTHEsContext)
  const { account } = useWeb3Wagmi()
  const { openWalletModal } = useWalletModal()
  const { onWithdraw, pending } = useWithdraw()
  const prices = usePrices()
  const theAsset = useTHEAsset()
  const [loading, setLoading] = useState(true)

  const managerAddress = (address) => {
    if (address.toLowerCase() === account.toLowerCase()) return 'Not delegated'
    const manager = managers.find((man) => {
      return man.address.toLowerCase() === address.toLowerCase()
    })
    if (manager) return upperFirst(manager.title)
    return truncateEthAddress(address)
  }

  const renderButton = () => {
    if (account) {
      return (
        <div className='flex items-center space-x-3 '>
          <img src='/images/lock/lock-icon.svg' alt='' />
          <span>CREATE LOCK</span>
        </div>
      )
    } else {
      return <span>CONNECT WALLET</span>
    }
  }

  useEffect(() => {
    if (veTHEs.length > 0) {
      setLoading(false)
    }
  }, [veTHEs])

  const filteredData = useMemo(() => {
    return !searchText ? veTHEs.filter((item) => item.amount.gt(0)) : veTHEs.filter((item) => item.id.toString().includes(searchText))
  }, [searchText, veTHEs])

  return (
    <>
      <div className='max-w-[1200px] min-h-[450px] mt-10 mx-auto'>
        <div className='lg:flex justify-between items-center'>
          <div className='max-w-[532px] w-full'>
            <p className='text-[#b8b6cb] text-sm md:text-base leading-[22px] md:leading-6 mt-1'>
              Lock LYNX into veLYNX to earn and govern. Vote with veLYNX to earn bribes and trading fees. veLYNX can be transferred, merged and split. You can
              hold multiple positions.{' '}
              <a href='https://lynex.gitbook.io/lynex/' target='_blank' rel='noreferrer'>
                <span className='text-sm md:text-base whitespace-nowrap text-themeOrange'>Learn More</span>
              </a>
            </p>
          </div>
          <div className='mt-3 xl:mt-0 w-full flex-col-reverse flex lg:flex-row items-center lg:justify-end lg:space-y-0 lg:space-x-3'>
            <SearchInput searchText={searchText} setSearchText={setSearchText} placeholder='Search veLYNX ID' onlyNumber={true} />
            <TransparentButton
              content={renderButton()}
              fontWeight={'font-medium'}
              className={
                'h-[42px] px-7 mb-3 lg:mb-0 text-white flex flex-col items-center justify-center w-full lg:w-auto text-base tracking-[1.04px] xl:tracking-[1.36px] font-semibold'
              }
              onClickHandler={() => {
                if (account) {
                  setIsCreateOpen(true)
                } else {
                  openWalletModal()
                }
              }}
            />

            <StyledButton
              onClickHandler={() => {
                setOption(3)
              }}
              content={'VOTE'}
              className={'h-[42px] w-full lg:w-auto mb-3 lg:mb-0 py-[8px] text-base px-[22px]'}
            />
          </div>
        </div>
        {!account ? (
          <div className='w-full mt-[14px] lg:mt-5'>
            <NoFound title={'Please connect your wallet'} />
          </div>
        ) : loading ? (
          <div className='flex flex-col w-full items-center p-3 mt-10'>
            <Spinner />
            <div className='text-white text-sm mt-2'>
              <p>Loading veLYNX..</p>
            </div>
          </div>
        ) : filteredData && filteredData.length > 0 ? (
          <div className='w-full mt-[14px] lg:mt-5'>
            <Sticky
              enabled={true}
              innerActiveClass={'bg-themeOrangeLight rounded-b-lg'}
              top={95}
              activeClass={''}
              innerClass={'sticky-table-header'}
              className={`z-[100]`}
            >
              <div className='w-[15%] font-medium text-[17px] xl:text-[18px] text-white f-f-fg'>veLYNX ID</div>
              <div className='w-[17%] font-medium text-[17px] xl:text-[18px] text-white f-f-fg'>Lock Value</div>
              <div className='w-[17%] font-medium text-[17px] xl:text-[18px] text-white f-f-fg'>Locked Amount</div>
              <div className='w-[17%] font-medium text-[17px] xl:text-[18px] text-white f-f-fg'>Lock Expire</div>
              <div className='w-[17%] font-medium text-[17px] xl:text-[18px] text-white f-f-fg'>Delegated</div>
              <div className='w-[17%] font-medium text-[17px] xl:text-[18px] text-white f-f-fg'></div>
            </Sticky>
            <div className='flex flex-col gap-3'>
              {filteredData.map((item, index) => {
                return (
                  <div key={`velynx-${index}`} className='table-row-wrapper'>
                    <div className='w-full lg:w-[15%] mt-3 lg:mt-0 text-[#DEDBF2] f-f-fg'>
                      <div className='lg:hidden text-[13px] font-semibold'>veLYNX ID</div>
                      <div className='text-lg lg:text-xl font-medium'>{item.id}</div>
                    </div>
                    <div className='w-full lg:w-[34%] flex mt-3 lg:mt-0 text-[#DEDBF2]'>
                      <div className='w-1/2'>
                        <div className='lg:hidden text-[13px] f-f-fg font-semibold'>Lock Value</div>
                        <div className='text-base sm:text-[17px] lg:text-[15px] xl:text-[17px]'>{formatAmount(item.voting_amount)} veLYNX</div>
                        <div className='text-[15px] text-dimGray'>${formatAmount(item.voting_amount.times(prices['LYNX']))}</div>
                      </div>
                      <div className='w-1/2'>
                        <div className='lg:hidden text-[13px] f-f-fg font-semibold'>Locked Amount</div>
                        <div className='text-base sm:text-[17px] lg:text-[15px] xl:text-[17px]'>{formatAmount(item.amount)} LYNX</div>
                        <div className='text-[15px] text-dimGray'>${formatAmount(item.amount.times(prices['LYNX']))}</div>
                      </div>
                    </div>
                    <div className='w-full lg:w-[34%] flex lg:items-center mt-3 lg:mt-0 text-[#DEDBF2]'>
                      <div className='w-1/2'>
                        <div className='lg:hidden text-[13px] f-f-fg font-semibold'>Lock Expire</div>
                        <div className='text-base sm:text-[17px] lg:text-[15px] xl:text-[17px] text-[#DEDBF2]'>
                          {item.lockEnd === 0 ? 'Permanent lock' : moment.unix(item.lockEnd).format('MMM DD, YYYY')}
                        </div>
                        {item.lockEnd !== 0 && <div className='text-[15px] text-dimGray'>{item.diffDates}</div>}
                      </div>
                      <div className='w-1/2 text-[#DEDBF2]'>
                        <div className='lg:hidden text-[13px] f-f-fg font-semibold'>Delegated</div>
                        <div
                          className={`text-base sm:text-[17px] lg:text-[15px] xl:text-[17px] f-f-fg font-semibold ${
                            item.delegatee?.toLowerCase() === account.toLowerCase() ? `text-themeOrange` : 'text-success'
                          }`}
                        >
                          {managerAddress(item.delegatee)}
                        </div>
                      </div>
                    </div>
                    <div className='w-full lg:w-[17%] flex lg:justify-end items-center space-x-[14.5px] mt-3 mb-2 lg:mb-0 lg:mt-0'>
                      {item.voting_amount.isZero() && !item.amount.isZero() ? (
                        <div
                          className='text-base text-green cursor-pointer'
                          onClick={() => {
                            if (!pending) {
                              onWithdraw(item)
                            }
                          }}
                        >
                          Withdraw
                        </div>
                      ) : (
                        <TransparentButton
                          onClickHandler={() => {
                            setSelectedItem(item)
                            setIsManageOpen(true)
                          }}
                          content={'MANAGE'}
                          className={
                            'h-10 px-[26px] text-white flex max-w-[96px] whitespace-nowrap flex-col items-center justify-center text-sm xl:text-[14px] w-full'
                          }
                        />
                      )}
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        ) : (
          <div className='w-full mt-[14px] lg:mt-5'>
            <NoFound title={'No positions found'} />
          </div>
        )}
      </div>
      {isCreateOpen && <CreateModal isOpen={isCreateOpen} setIsOpen={setIsCreateOpen} theAsset={theAsset} />}
      {isManageOpen && <ManageModal isOpen={isManageOpen} setIsOpen={setIsManageOpen} selected={selectedItem} theAsset={theAsset} />}
    </>
  )
}

export default Lock
