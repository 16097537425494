export const badgeDefinitions = {
  lxp: {
    name: 'Linea LXP-L',
    logo: 'images/points/lxp.svg',
    link: 'https://linea.build/surge',
    priority: 1,
  },
  el: {
    name: 'Eigenlayer Restaked Points',
    logo: 'images/points/el.svg',
    link: 'https://docs.eigenlayer.xyz/eigenlayer/restaking-guides/restaking-user-guide/restaked-points',
    priority: 2,
  },
  ez: {
    name: 'x4 Renzo ezPoints',
    logo: 'images/points/ez.svg',
    link: 'https://mirror.xyz/10/0x965e92f21b8da409de9d1ca8e8a99e4d326e9bd5/render',
    priority: 3,
  },
  turtleEtherfi: {
    name: 'Turtle-Etherfi Points',
    logo: 'images/points/turtle-etherfi.svg',
    link: 'https://turtle.club/',
    priority: 8,
  },
  turtleLinea: {
    name: 'Turtle-Linea Points',
    logo: 'images/points/turtle-linea.svg',
    link: 'https://turtle.club/',
    priority: 9,
  },
  turtleLynex: {
    name: 'Turtle-Lynex Points',
    logo: 'images/points/turtle-lynex.svg',
    link: 'https://turtle.club/',
    priority: 10,
  },
  bedrock: {
    name: 'Bedrock Diamonds',
    logo: 'images/points/bedrock.svg',
    link: 'https://docs.bedrock.technology/bedrock-lrt/bedrock-diamonds',
    priority: 5,
  },
  etherfi: {
    name: '3x Ether.Fi points',
    logo: 'images/points/etherfi.svg',
    link: 'https://etherfi.gitbook.io/etherfi/getting-started/loyalty-points',
    priority: 4,
  },
  totems: {
    name: 'Inception Totems',
    logo: 'images/points/totems.svg',
    link: 'https://docs.inceptionlrt.com/inceptionlrt/totems',
    priority: 5,
  },
  davos: {
    name: 'Davos Peaks',
    logo: 'images/points/davos.svg',
    link: 'https://davos.xyz/',
    priority: 6,
  },
  layerbank: {
    name: 'LayerBank Points',
    logo: 'images/points/layerbank.svg',
    link: 'https://donvegas.medium.com/introducing-layerbanks-linea-points-program-your-path-to-a-potential-airdrop-7de1c2f7af2c',
    priority: 7,
  },
}

export const getBadgesForPool = (address) => {
  const badgeKeys = poolBadgeMap[address] || []
  const badges = badgeKeys.map((key) => badgeDefinitions[key] || {}).sort((a, b) => a.priority - b.priority)
  return badges
}

// Mapping from pool addresses to badges
export const poolBadgeMap = {
  '0x8a9570ec97534277ade6e46d100939fbce4968f0': ['lxp', 'turtleLynex', 'turtleLinea'], // WBTC/WETH (Narrow)
  '0xa4477d98e519d4c1d66aef4efdf7cbeb84f4f778': ['lxp'], // sAMM-USDC/USDT
  '0x0b15a5e3ca0d4b492c3b476d0f807535f9b72079': ['lxp'], // USDC/WETH (Narrow)
  '0xf3b1125c8505f038503e002e61a78253610d4f60': ['lxp', 'turtleLynex', 'turtleLinea'], // USDT/WETH (Narrow)
  '0xea5abd5d15b8cd168e9eff78047db52af079e094': ['lxp'], // wstETH/WETH (Narrow)
  '0x2babed912322c355f4b8b58ea568727892ca3f05': ['lxp'], // USDC/USDT (Stable)
  '0x392ac7d2d09042ec9fafaeb8d52a88dc591e9fea': ['lxp', 'turtleLynex', 'turtleLinea', 'ez'], // ezETH/WETH (Narrow)
  '0xe5e19bbf39c76a8c980b48f2ec89d0de877bf567': ['lxp'], // vAMM-USDC/MENDI
  '0xa8e4b5401f98cf20f7e4e9323b02c40f10661f06': ['lxp'], // sAMM-wstETH/WETH
  '0x511481ef0deb10eb5c1e36b72140718c58921265': ['lxp', 'turtleLynex', 'turtleLinea'], // LYNX/WETH (YieldIQ)
  '0xad53f20917b144cd0b100dbe75988c0e4b919dba': ['lxp'], // WBTC/WETH (YieldIQ)
  '0xcc86572ce5a6eee74c76c57e9ea7b08221f06bb9': ['lxp'], // USDC/LYNX (Wide)
  '0xe9e146f5bcebd71fb8c526ea036de6bbfb1b0ad7': ['lxp'], // sAMM-USDC/MAI
  '0xae5e4a028120b711c5c5dc8a9162bcdaa88936ae': ['lxp', 'turtleLynex', 'turtleLinea'], // LYNX/WBTC (YieldIQ)
  '0x92ccb773da4f3974c941974bdb978bad7efa7744': ['lxp', 'turtleLynex', 'turtleLinea'], // USDC/LYNX (YieldIQ)
  '0xa3d9745dcd138bd2ba4b039fa61c042756bfe1e8': ['lxp', 'turtleLynex', 'turtleLinea'], // USDC/WBTC (Narrow)
  '0x7088a31d53fc1fa300ceb5f9103343137a62b545': ['lxp', 'davos'], // sAMM-USDC/DUSD
  '0x8f3e0a2378b0b5838e0e0d99fcecc167d47bc9a7': ['lxp'], // sAMM-STONE/WETH
  '0xcffbfd665bedb19b47837461a5abf4388c560d35': ['lxp', 'turtleLynex', 'turtleLinea'], // STONE/WETH (Narrow)
  '0x58aacbccaec30938cb2bb11653cad726e5c4194a': ['lxp'], // sAMM-USDC/USD+
  '0x9d9070462eb1cbfe2b8cfa337bb4d0a3ac420bd6': ['lxp'], // FOXY/WETH (YieldIQ)
  '0xc5f4c5c2077bbbac5a8381cf30ecdf18fde42a91': ['lxp'], // sAMM-USDT+/USD+
  '0x3e78c1f766d7fe2c3dcef6afe6609966540b6391': ['lxp'], // vAMM-USDC/LYNX
  '0x1795c4b23ec93c852653045dab4dd1ac796d5248': ['lxp'], // FOXY/WETH (YieldIQ)
  '0x8421c6102ee8a147facc01977df3b159f7921d54': ['lxp'], // MATIC/WETH (Narrow)
  '0x555267747b0918abce10335f154fbe7b636f7804': ['lxp', 'turtleLynex', 'turtleLinea'], // LYNX/USDT (YieldIQ)
  '0xaad8a008496d789f48f23c4778b4fbddb7a1c937': ['lxp'], // vAMM-axlLqdr/WETH
  '0x77a0006fdeb1ce7f521a01852a632b2a21490d67': ['lxp'], // USDC/EURO3 (Stable)
  '0xf1866f414ce68512a5e2923673aa376341cb6cde': ['lxp'], // sAMM-wDAI/USDC
  '0xe57f293520cbf7d5a17629cbfb06eabdb759a151': ['lxp', 'turtleLynex', 'turtleLinea'], // LYNX/STONE (YieldIQ)
  '0xbde12bdbeb92b0dca3f3c37a749633a24717b24d': ['lxp'], // vAMM-USDC/A3A
  '0x8d2a23eb25a0a8213319a4ed2984602a7c23f06b': ['lxp'], // vAMM-WBTC/WETH
  '0xcc3880b990b11a712f2f42c0c0db57a0ca1529a9': ['lxp'], // vAMM-USDC/NDX_2412
  '0xccf4fd9f2fbe89917821e4629cb2a6b760651baf': ['lxp'], // vAMM-XRGB/WETH
  '0xf93f79c2557ebc38c6eb9d020b8ef50b8eb97b48': ['lxp'], // vAMM-USDC/iNDX_2412
  '0x6fb44889a9aa69f7290258d3716bffcb33cde184': ['lxp'], // vAMM-USDC/WETH
  '0x1c6fb08c1ef4e505a4ae3ffc3c99e443070df64a': ['lxp'], // vAMM-LYNX/WETH
  '0x94769abfbeb114cf7ba2e7b9cef242ac70da20d6': ['lxp'], // vAMM-USDT/WETH
  '0x530071b0373ab3029cad32e0c19b75253e231b69': ['lxp', 'etherfi', 'el', 'turtleLynex', 'turtleLinea'], // weETH/WETH (Narrow)
  '0x0a4a0c5bc4d404d48a5a47ef9197500acbdea0a8': ['lxp', 'bedrock', 'el', 'turtleLynex', 'turtleLinea'], // weETH/WETH (Narrow)
  '0x24c6d82d316f80421f3e0f58c70127a97a5f1db7': ['lxp', 'turtleLynex', 'turtleLinea'], // WBTC/WETH (Elastic Expansion)
  '0x8dabf94c7bdd771e448a4ae4794cd71f9f3d7a0d': ['lxp'], // EURO3/USD+
  '0x96411166448feed82661e164c38fc71d0ff169b0': ['lxp', 'turtleLynex', 'turtleLinea'], // USDT/WETH Steer (Classic Rebalance)
  '0x504f0d95d3a43a6c9fcd86782a93e043e78d1ab0': ['lxp', 'turtleLynex', 'turtleLinea'], // vAMM-USDT/WETH
  '0xbe23da11fbf9da0f7c13ea73a4bb511b9bc00177': ['lxp'], // sAMM-USDT/USDT+
  '0x9e480e37a036b6703818d701544763a36bfed35b': ['lxp'], // WETH/ZERO YieldIQ
  '0xc491c1b173e932e97d9f739ccd9ae5b6d5fce4ce': ['lxp', 'totems', 'turtleLynex', 'turtleLinea'], // inETH/wstETH Gamma
  '0x6f501662a76577fbb3bb230be5e8e69d41d8c711': ['lxp'], // FLY/USD+ vAMM
  '0xcc2be07ad5d1bd4621726cfddbef2ffd248b8d70': ['lxp'], // ankrETH/wstETH Correlated
  '0x27d09198fe47ac4e3e9f622032a71e26df776e99': ['lxp'], // ankrETH/ANKR Narrow
  '0x31f5fb5d70b00398821b0e1d1db9bab4a0231fc3': ['lxp'], // vAMM-QI/WETH Narrow
  '0x1e0015f45e339c08f7c891f83e579fbe9ea9cd37': ['lxp', 'layerbank'], // sAMM-solvBTC/WBTC
  '0x54001e83fcf7654e8a974c0ed2ef4b9cc4e6ff63': ['lxp', 'layerbank', 'turtleLynex', 'turtleLinea'], // solvBTC/WETH Steer
  '0x1bb15aa9a8bdeea7bb3a21bd239a232c5a09b0c6': ['lxp', 'layerbank'], // sAMM-M-BTC/WBTC
  '0xb7d02ec92fe7bf5e47fcf82ac21575e01fe4f5f1': ['lxp', 'layerbank'], // sAMM-M-BTC/solvBTC.m
  '0x1577fb60deb25dc9d9c6f0ed736a682b849e6bfb': ['lxp', 'layerbank'], // vAMM-M-BTC/WETH
  '0x904e2bbdb669cb30f51d69daefdcdeb83759d833': ['lxp'], // vAMM-ZERO/WETH
  '0xfb26fdeb0d7151731afccdffb0e38d2c5f33c807': ['lxp'], // vAMM-LINUS/WETH
  '0xadd8d818b15c7ed8f4872d4e1fd409238668ffcc': ['lxp'], // USDC/DAI DefiEdge (Stable)
  '0xd14a66cb33d07cc019d990a1f62c7ab9bc20a418': ['lxp'], // DAI/WETH (Volatile)
  '0x8a33eae82534b8e6cce270538faf83d11734f1f1': ['lxp'], // sAMM-USDC/USDe
  '0x091872441a89b699ccc805c1d1ec67c4f2c8fa2c': ['lxp'], // DOGE/WETH DEFIEDGE VOLATILE
  '0x624919d32d1563e33710b585f212664701869e64': ['lxp'], // CROAK/WETH (Wide)
  '0xba4becdb0394d3db69bca50f70cacb7d2bab0ea9': ['lxp'], // vAMM-LYNX/oLYNX
  '0x620855ac3dcf06dbb12647b997a991f479442f7d': ['lxp'], // SHIB/WETH (Volatile)
  '0xa45f03ffa6d5b7c5f8484b02ed5301ed59d47c4d': ['lxp'], // NWG/WETH (Wide)
  '0xea392b8332378ca039d6b91d024b94ce9d1c31b6': ['lxp'], // PEPE/WETH (Wide)
  '0x7fee4f637bc38e73ebf4caed22a1941236b79bf4': ['lxp'], // vAMM-FAITH/WETH
  '0x8f63b44d31fbca1d62ab4e32663fb91b4a363d01': ['lxp'], // vAMM-FOXY/WETH
  '0xe6c38e4572281e390a7231b9dc96f1a52e78ec4c': ['lxp'], // vAMM-MECH/WETH
  '0xad2406af7fb59f9949af7d355aa19941178809a8': ['lxp'], // WETH/MENDI (YieldIQ)
  '0x4232f47bf47ed5b4ad84abb835e5db267929ffed': ['lxp'], // USDC/USDT Clip Tight
  '0x2a24de4f0e84168dfe8c022ae811eaaa6d16fba6': ['lxp'], // CLIP/WETH - Clip wide
  '0x46463aa62249e21188ca9babbff08b5c4542eefd': ['lxp'], // USDC/eUSD - sAMM-USDC/eUSD
  '0x52555fa67687288fc70791beae2b92754fdd0341': ['lxp'], // LUCIA/WETH - vAMM-LUCIA/WETH
  '0x36cdb4222ce551d74e4001a660b628cbbe0e4c9b': ['lxp'], // HOTDOG/WETH - vAMM-HOTDOG/WETH
  '0xcc92940d2a0ccad9b57a3f26e94a1ea80bf224d3': ['lxp'], // CLIP/WETH - Clip Narrow
  '0xf0759fcda52471ac0bdf434e4e043d0193a0f639': ['lxp'], // BULL/WETH - vAMM-BULL/WETH
  '0x2549d6b5658a75d7eae869af8cd8ae66507f0905': ['lxp'], // USDC/WETH - Clip Dynamic
}
