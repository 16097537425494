import React, { useContext, useEffect, useState, useMemo } from 'react'
import BigNumber from 'bignumber.js'
import { useWeb3Wagmi } from '../../../../../hooks/useWeb3Context'
import { BaseAssetsConetext } from '../../../../../context/BaseAssetsConetext'
import { dexTokens } from '../../../../../config/tokens/tokenlists'
import Spinner from '../../../../common/Spinner'
import { BIG_ZERO, formatAmount } from '../../../../../utils/formatNumber'
import { veTHEsContext } from '../../../../../context/veTHEsConetext'
import { useGetAllVeRewards } from '../../../../../hooks/useRewards'
import { useGetFees } from '../../../../../hooks/useRewards'
import usePrices from '../../../../../hooks/usePrices'
import { FusionsContext } from '../../../../../context/FusionsContext'
// import { useAllHarvest } from '../../../../../hooks/useGauge'
import '../../dashboard.scss'

const Assets = () => {
  const { account } = useWeb3Wagmi()
  const baseAssets = useContext(BaseAssetsConetext)
  const [veLynx, setVeLynx] = useState(BIG_ZERO)
  const { veTHEs } = useContext(veTHEsContext)
  const filteredVeTHEs = useMemo(() => {
    return veTHEs?.filter((item) => item.amount.gt(0) || item.rebase_amount.gt(0))
  }, [veTHEs])
  const [userAssets, setUserAssets] = useState(null)
  const [loadingAssets, setLoadingAssets] = useState(true)
  const { rewards: veRewards, init } = useGetAllVeRewards()
  const fees = useGetFees()
  const prices = usePrices()

  const fusions = useContext(FusionsContext)
  const earnedPairs = useMemo(() => {
    return fusions.filter((pair) => !pair.account.earnedUsd.isZero())
  }, [fusions, account])
  // const { onAllHarvest, pending: pendingFarming } = useAllHarvest()

  // Fetch user aggregated farming rewards to claim
  const farmingRewards = useMemo(() => {
    if (!earnedPairs || earnedPairs.length === 0) return BIG_ZERO
    let total = [...earnedPairs].reduce((sum, current) => {
      if (account) return sum.plus(current.account.earnedUsd)
    }, new BigNumber(0))
    return total
  }, [fusions])

  // Fetch user aggregated voting rewards to claim
  const votingRewards = useMemo(() => {
    let total = new BigNumber(0)
    veRewards.map((veReward) => {
      let subtotal = [...veReward, ...fees].reduce((sum, current) => {
        if (current.totalUsd.gt(0)) return sum.plus(current.totalUsd.toNumber())
        else return sum
      }, new BigNumber(0))
      total = total.plus(subtotal)
    })
    if (filteredVeTHEs?.length > 0) {
      filteredVeTHEs.map((veTHE) => {
        total = total.plus(veTHE.rebase_amount.times(prices['LYNX']))
      })
    }
    return total
  }, [veRewards, fees, filteredVeTHEs, prices, account])

  // Fetch user assets
  useEffect(() => {
    const fetchAssets = async () => {
      const assets = baseAssets.filter((asset) => dexTokens.find((a) => a.address.toLowerCase() === asset.address.toLowerCase()))
      setLoadingAssets(false)
      setUserAssets(assets)
    }
    if (account && baseAssets) fetchAssets()
  }, [baseAssets, account])

  // Fetch user aggregated veLYNX value
  useEffect(() => {
    const fetchVeLynx = () => {
      let result = BIG_ZERO
      filteredVeTHEs?.map((veTHE) => (result = result.plus(veTHE.voting_amount)))
      setVeLynx(result)
    }
    if (account) fetchVeLynx()
  }, [filteredVeTHEs, account])

  return (
    <div className='section'>
      <p className='title'>Assets</p>
      {loadingAssets ? (
        <Spinner />
      ) : (
        <div className='flex flex-col md:flex-row w-full space-y-4 md:space-y-0 md:space-x-4'>
          <div className='dashboard-container'>
            <p className='text-dimGray font-medium'>Ecosystem Holdings</p>
            <div className='grid grid-cols-2 md:grid-cols-4 -mx-1'>
              {userAssets.map((asset, idx) => {
                return (
                  <div key={`${asset.symbol} ${idx}`} className='asset'>
                    <p className='text-xs text-dimGray mt-1'>{asset.symbol}</p>
                    <div className='flex space-x-2 items-center justify-start'>
                      <img className='w-6' alt='' src={asset.logoURI || '/images/tokens/UKNOWN.png'} />
                      <p className='text-xl font-medium text-white tracking-wide'>{formatAmount(asset.balance)}</p>
                    </div>
                  </div>
                )
              })}
              <div className='asset'>
                <p className='text-xs text-dimGray mt-1'>veLYNX</p>
                <div className='flex space-x-2 items-center justify-start'>
                  <img className='w-6' alt='' src={'https://app.lynex.fi/images/tokens/veLynx.svg'} />
                  <p className='text-xl font-medium text-white tracking-wide'>{formatAmount(veLynx)}</p>
                </div>
              </div>
            </div>
          </div>
          <div className='dashboard-container'>
            <p className='text-dimGray font-medium'>Rewards</p>
            <div className='flex w-full justify-between space-x-4'>
              <div className='asset w-full'>
                <p className='text-xs text-dimGray mt-1'>Voting</p>
                <div className='flex w-full'>
                  <div className='flex flex-col md:flex-row w-full items-start md:items-center justify-between'>
                    {!init && filteredVeTHEs?.length !== 0 ? (
                      <div className='p-1'>
                        <Spinner size={20} />
                      </div>
                    ) : (
                      <p className='text-xl font-medium text-white tracking-wide'>$ {formatAmount(votingRewards)}</p>
                    )}
                    {/*
                    <button
                      className={`text-[#e6a160] ntext-base `}
                      onClick={
                        () => setOption(4)
                        
                        // async () => {
                        // veTHEs.map(async (veTHE) => {
                        //   await onClaimAll(veRewards, veTHE)
                        //   setInit(false)
                        // })
                       //   
                      // }
                        
                      }
                    >
                      Claim
                    </button>
                    */}
                  </div>
                </div>
              </div>
              <div className='asset w-full'>
                <p className='text-xs text-dimGray mt-1'>Farming</p>
                <div className='flex w-full'>
                  <div className='flex flex-col md:flex-row w-full items-start md:items-center justify-between'>
                    {fusions.length > 0 ? (
                      <p className='text-xl font-medium text-white tracking-wide'>$ {formatAmount(farmingRewards)}</p>
                    ) : (
                      <div className='p-1'>
                        <Spinner size={20} />
                      </div>
                    )}
                    {/*
                    <button
                      disabled={farmingRewards?.isZero() || pendingFarming}
                      className={`${farmingRewards?.isZero() || pendingFarming ? 'opacity-[0.66] cursor-not-allowed text-gray' : 'text-[#e6a160]'} text-base `}
                      onClick={() => {
                        onAllHarvest(earnedPairs)
                      }}
                    >
                      Claim
                    </button>
                    */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default Assets
