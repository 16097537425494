import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import MyAssets from '../../components/pages/dashboard/myassets/myAssets'
import Rewards from '../rewards'
import Lock from '../lock'
import Delegate from '../delegate'
import Vote from '../vote'
import Otoken from '../otoken'
import { useWeb3Wagmi } from '../../hooks/useWeb3Context'
import useWalletModal from '../../hooks/useWalletModal'
import StyledButton from '../../components/common/Buttons/styledButton'

const options = [
  {
    label: 'My Assets',
    component: MyAssets,
    id: 0,
    path: '/dashboard',
  },
  {
    label: 'Lock',
    component: Lock,
    id: 1,
    path: '/dashboard/lock',
  },
  {
    label: 'Delegate',
    component: Delegate,
    id: 2,
    path: '/dashboard/delegate',
  },
  {
    label: 'Vote',
    component: Vote,
    id: 3,
    path: '/dashboard/vote',
  },
  {
    label: 'Rewards',
    component: Rewards,
    id: 4,
    path: '/dashboard/rewards',
  },
  {
    label: 'Options',
    component: Otoken,
    id: 5,
    path: '/dashboard/options',
  },
]

const Dashboard = ({ defaultOption }) => {
  const [option, setOption] = useState(defaultOption || 0)
  const { account } = useWeb3Wagmi()
  const { openWalletModal } = useWalletModal()
  const navigate = useNavigate()
  useEffect(() => {
    if (defaultOption !== undefined) {
      setOption(defaultOption)
    }
  }, [defaultOption])

  return (
    <>
      <div className='pt-[160px] md:pt-[140px]'>
        <div className='grid grid-cols-3 gap-2 md:flex justify-center md:space-x-8 w-full px-3 md:px-0 py-3 bg-white/5'>
          {options.map((op, idx) => (
            <div
              onClick={() => navigate(op.path)}
              key={`option ${idx}`}
              className={`flex justify-center items-center px-3 py-2 rounded-lg text-base tracking-wide text-dimGray cursor-pointer ${
                option === op.id && 'text-white bg-themeOrangeLight/10'
              }  f-f-fg`}
            >
              {op.label}
            </div>
          ))}
        </div>
      </div>
      <div className='max-w-[1200px] px-5 sm:px-16 md:px-28 mdLg:px-40  lg:px-5 xl:px-0 mx-auto'>
        <div className='lg:flex items-end justify-between lg:space-x-[60px]'></div>
        {account || (option !== 0 && option !== 1 && option !== 4) ? (
          options.map((op, idx) => {
            if (op.id === option) {
              return <op.component key={idx} setOption={setOption} />
            }
          })
        ) : (
          <div className='flex flex-col space-y-4 items-center justify-center my-[128px] mx-auto max-w-[380px]'>
            <img alt='not-found' width={64} src='/images/common/not-found.svg' />
            <p className='text-sm text-dimGray text-base'>Connect your wallet to see your dashboard</p>
            <StyledButton onClickHandler={() => openWalletModal()} content={'CONNECT WALLET'} className='py-2 px-3 text-sm w-full' />
          </div>
        )}
      </div>
    </>
  )
}

export default Dashboard
