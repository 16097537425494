import React from 'react'
import Toggle from '../Toggle'
import TabFilter from '../TabFilter'
import MobileDropDown from '../MobileDropDown'
import { TabSizes } from '../../../config/constants'

const MobileFilterModal = ({
  setMobileFilter,
  setFilter,
  filter,
  tabs,
  isStaked,
  setIsStaked,
  sortOptions,
  sort,
  setSort,
  isVote = false,
  isVoted,
  setIsVoted,
  memeOnly,
  setMemeOnly,
  isInactive,
  setIsInactive,
}) => {
  return (
    <>
      <div className='fixed bg-transparent w-full h-full lg:hidden inset-0 z-40' onClick={() => setMobileFilter(false)} />
      <div className={`absolute w-full bg-themeBlack lg:hidden border  border-white rounded-[5px] p-3 z-50 top-4`}>
        <p className='text-white font-semibold f-f-fg text-xl mb-3'>Filters</p>
        {filter && <TabFilter data={tabs} filter={filter} setFilter={setFilter} size={TabSizes.SMALL} />}
        <div className='w-full flex items-center space-x-7 mt-4'>
          <p className='text-white font-medium whitespace-nowrap'>Sort by</p>
          <MobileDropDown options={sortOptions} sort={sort} setSort={setSort} />
        </div>
        {isVote ? (
          <>
            <div className='flex items-center space-x-2 mt-4'>
              <Toggle checked={isVoted} onChange={() => setIsVoted(!isVoted)} small toggleId='votes' />
              <p className='text-[#DEDBF2] text-[16px] whitespace-nowrap'>Show My Votes</p>
            </div>
            <div className='flex items-center space-x-2 mt-4'>
              <Toggle checked={memeOnly} onChange={() => setMemeOnly(!memeOnly)} small toggleId='meme' />
              <p className='text-[#DEDBF2] text-[16px] whitespace-nowrap'>Meme Carnival Only</p>
            </div>
          </>
        ) : (
          <div className='flex items-center space-x-2 mt-4'>
            <div className='flex items-center space-x-2 w-1/2'>
              <Toggle checked={isStaked} onChange={() => setIsStaked(!isStaked)} small toggleId='staked' />
              <p className='text-[#DEDBF2] text-[14px] whitespace-nowrap'>Staked Only</p>
            </div>
            <div className='flex items-center space-x-2 w-1/2'>
              <Toggle checked={isInactive} onChange={() => setIsInactive(!isInactive)} small toggleId='inactive' />
              <p className='text-[#DEDBF2] text-[14px] whitespace-nowrap'>Inactive Gauges</p>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default MobileFilterModal
